



import { Component, Vue, Emit, Watch } from 'vue-property-decorator';
import VeeValidate from '../../../plugins/vee-validate';
import { GetAccountResponse, ErrorResponse } from '../../../../types/typescript-axios/api';
import axios, {AxiosResponse, AxiosError} from 'axios';
import StepNavi from '../../../components/contents/stepNavi/StepNavi.vue';
import Kiyaku from '@/components/contents/kiyaku/kiyaku.vue';
import VueSimpleSuggest from 'vue-simple-suggest';
import 'vue-simple-suggest/dist/styles.css';
import { getSuggestionList } from '@/plugins/helper';

@Component({
  mixins: [ VeeValidate ],
  components: {
    StepNavi,
    Kiyaku,
    VueSimpleSuggest,
  },
})
export default class SignupForm extends Vue {
  private errorMessage: boolean = false;

  private companyNames: string[] = [];

  private get stepNavi(): string {
    const $parent: any = this.$parent;
    return $parent.stepNavi;
  }

  // @Emit()
  // private setCompanyName(value: string): void {
  //   this.companyName = value;
  // }
  get signupUrl(): string {
    return process.env.VUE_APP_PATH_SIGNUP;
  }

  get companyOptions(): string[] {
    const $parent: any = this.$parent;
    return $parent.companyOptions;
  }

  get birthdayOptions(): number[] {
    const $parent: any = this.$parent;
    return $parent.birthdayOptions;
  }

  private get agree(): string {
    const $parent: any = this.$parent;
    return $parent.agree;
  }
  private set agree(value: string) {
    this.inputAgree(value);
  }
  private get mail(): string {
    const $parent: any = this.$parent;
    return $parent.mail;
  }
  private set mail(value: string) {
    this.inputMail(value);
  }
  private get name1(): string {
    const $parent: any = this.$parent;
    return $parent.name1;
  }
  private set name1(value: string) {
    this.inputName1(value);
  }
  private get name2(): string {
    const $parent: any = this.$parent;
    return $parent.name2;
  }
  private set name2(value: string) {
    this.inputName2(value);
  }
  private get birthYear(): string {
    const $parent: any = this.$parent;
    return $parent.birthYear;
  }
  private set birthYear(value: string) {
    this.inputBirthYear(value);
  }
  private get companyName(): string {
    const $parent: any = this.$parent;
    return $parent.companyName;
  }
  private set companyName(value: string) {
    this.inputCompanyName(value);
  }
  private get departmentName(): string {
    const $parent: any = this.$parent;
    return $parent.departmentName;
  }
  private set departmentName(value: string) {
    this.inputDepartmentName(value);
  }
  private get sex(): string {
    const $parent: any = this.$parent;
    return $parent.sex;
  }
  private set sex(value: string) {
    this.inputSex(value);
  }
  private get consent(): string {
    const $parent: any = this.$parent;
    return $parent.consent;
  }
  private set consent(value: string) {
    this.inputConsent(value);
  }
  private get password(): string {
    const $parent: any = this.$parent;
    return $parent.password;
  }
  private set password(value: string) {
    this.inputPassword(value);
  }
  private get mailReInput(): string {
    const $parent: any = this.$parent;
    return $parent.mailReInput;
  }
  private set mailReInput(value: string) {
    this.inputMailReInput(value);
  }
  private get passwordReInput(): string {
    const $parent: any = this.$parent;
    return $parent.passwordReInput;
  }
  private set passwordReInput(value: string) {
    this.inputPasswordReInput(value);
  }
  private get secretQuestAans(): string {
    const $parent: any = this.$parent;
    return $parent.secretQuestAans;
  }
  private set secretQuestAans(value: string) {
    this.inputSecretQuestAans(value);
  }
  private get tel(): string {
    return this.tel1 + this.tel2 + this.tel3;
  }
  private get tel1(): string {
    const $parent: any = this.$parent;
    return $parent.tel1;
  }
  private set tel1(value: string) {
    this.inputTel1(value);
  }
  private get tel2(): string {
    const $parent: any = this.$parent;
    return $parent.tel2;
  }
  private set tel2(value: string) {
    this.inputTel2(value);
  }
  private get tel3(): string {
    const $parent: any = this.$parent;
    return $parent.tel3;
  }
  private set tel3(value: string) {
    this.inputTel3(value);
  }

  @Emit()
  private inputAgree(value: string) {
    const $parent: any = this.$parent;
    $parent.setAgree(value);
  }
  private inputMail(value: string) {
    const $parent: any = this.$parent;
    $parent.setMail(value);
  }
  private inputName1(value: string) {
    const $parent: any = this.$parent;
    $parent.setName1(value);
  }
  private inputName2(value: string) {
    const $parent: any = this.$parent;
    $parent.setName2(value);
  }
  private inputBirthYear(value: string) {
    const $parent: any = this.$parent;
    $parent.setBirthYear(value);
  }
  private inputCompanyName(value: string) {
    const $parent: any = this.$parent;
    $parent.setCompanyName(value);
  }
  private inputDepartmentName(value: string) {
    const $parent: any = this.$parent;
    $parent.setDepartmentName(value);
  }
  private inputSex(value: string) {
    const $parent: any = this.$parent;
    $parent.setSex(value);
  }
  private inputConsent(value: string) {
    const $parent: any = this.$parent;
    $parent.setConsent(value);
  }
  private inputPassword(value: string) {
    const $parent: any = this.$parent;
    $parent.setPassword(value);
  }
  private inputMailReInput(value: string) {
    const $parent: any = this.$parent;
    $parent.setMailReInput(value);
  }
  private inputPasswordReInput(value: string) {
    const $parent: any = this.$parent;
    $parent.setPasswordReInput(value);
  }
  private inputSecretQuestAans(value: string) {
    const $parent: any = this.$parent;
    $parent.setSecretQuestAans(value);
  }
  private inputTel1(value: string) {
    const $parent: any = this.$parent;
    $parent.setTel1(value);
  }
  private inputTel2(value: string) {
    const $parent: any = this.$parent;
    $parent.setTel2(value);
  }
  private inputTel3(value: string) {
    const $parent: any = this.$parent;
    $parent.setTel3(value);
  }

  @Emit()
  private onBlurTelFields() {
    const $observer: any = this.$refs.observerTel;
    $observer.validate();
  }

  @Emit()
  private onSubmit(): void {
    const $observer: any = this.$refs.observer;
    $observer.reset();
    $observer.validate().then((result: boolean) => {
      if (result) {
        this.$router.replace('/signup/form/confirm/');
      } else {
        this.errorMessage = true;
        Vue.nextTick()
        .then(() => {
          const errorDome: any = this.$refs.errorMessage;
          window.scrollTo({
            top: window.pageYOffset + errorDome.getBoundingClientRect().top,
            behavior: 'smooth',
          });
        });
      }
    });
  }
  @Emit()
  private pageBack(): void {
    // return window.sessionStorage.getItem('fromPath');
    window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
    // this.$router.replace({ path: '../' });
  }
  @Emit()
  private suggestOff() {
    const ref: any = this.$refs;
    ref.suggest.hideList();
  }
  @Emit()
  private suggestOn() {
    const ref: any = this.$refs;
    ref.suggest.showList();
  }
  @Emit()
  private companyBlur() {
    window.setTimeout(() => this.suggestOff(), 300);
  }
  @Emit()
  private async suggestionList() {
    return await axios.get('/wp-json/moc/company')
    .then((res) => this.companyNames = res.data )
    .catch( (e: AxiosError<ErrorResponse>) => {
      console.log(e);
    });
  }
}
