var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form"},[_c('section',{staticClass:"form-page-section section"},[_c('div',{staticClass:"form-page-container container"},[_c('h1',{staticClass:"form-page-ttl title is-1 is-size-3-mobile has-text-weight-normal",attrs:{"data-test":"title"}},[_vm._v("ワーカーズフォーラムMOC会員　新規会員登録")]),_c('StepNavi',{attrs:{"data":_vm.stepNavi,"active":"0"}}),_vm._m(0),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.errorMessage),expression:"errorMessage"}],ref:"errorMessage",staticClass:"login-page-error-top is-size-6-mobile has-text-danger has-text-weight-bold has-text-centered py-6"},[_vm._v("入力内容に誤りがあるか登録されていません。"),_c('br'),_vm._v("ご確認の上、再度入力してください。")]),_vm._m(1),_c('div',{staticClass:"form-page-terms-box mb-6",attrs:{"data-test":"term"}},[_c('Kiyaku')],1),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ObserverProps){return [_c('form',{attrs:{"action":"","novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"rules":_vm.ruleAccountAgree},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"is-flex is-justify-content-center mb-6 pb-4 pt-1"},[_c('div',{staticClass:"checkbox-btn has-text-centered"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.agree),expression:"agree"}],class:{ 'is-danger' : errors.length > 0},attrs:{"type":"checkbox","id":"agree","name":"会員規約に同意する","value":"1"},domProps:{"checked":Array.isArray(_vm.agree)?_vm._i(_vm.agree,"1")>-1:(_vm.agree)},on:{"change":function($event){var $$a=_vm.agree,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="1",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.agree=$$a.concat([$$v]))}else{$$i>-1&&(_vm.agree=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.agree=$$c}}}}),_c('label',{staticClass:"checkbox",attrs:{"for":"agree","data-test":"input-agree"}},[_vm._v(" 会員規約に同意する ")]),_c('p',{staticClass:"has-text-danger has-text-weight-bold mt-1"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_c('h2',{staticClass:"form-page-ttl03 title is-3 has-text-secondary",attrs:{"data-test":"title2"}},[_vm._v(" 会員様情報入力 ")]),_c('table',{staticClass:"form-page-table"},[_c('tr',{staticClass:"form-page-table-tr",attrs:{"data-test":"form-row-name"}},[_c('th',{staticClass:"form-page-table-th"},[_c('div',{staticClass:"is-flex is-align-items-center is-flex-wrap-wrap"},[_c('span',{staticClass:"form-page-table-th-text is-block has-text-weight-bold",attrs:{"data-test":"form-row-heading"}},[_vm._v("お名前")]),_c('span',{staticClass:"form-label-required tag is-required",attrs:{"data-test":"form-row-require"}},[_vm._v("必須")])])]),_c('td',{staticClass:"form-page-table-td"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var errors = ref.errors;
return [_c('div',{staticClass:"form-page-table-td-box is-flex is-align-items-center"},[_c('div',{staticClass:"form-page-table-name is-flex is-align-items-center"},[_c('div',{staticClass:"form-page-table-name-text is-flex-shrink-0"},[_vm._v("姓")]),_c('validation-provider',{attrs:{"rules":_vm.ruleAccountNameSei,"vid":"name1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name1),expression:"name1"}],staticClass:"input form-page-table-name-input",class:{ 'is-danger' : errors.length > 0},attrs:{"type":"text","name":"姓","data-test":"input-name1"},domProps:{"value":(_vm.name1)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name1=$event.target.value}}})]}}],null,true)})],1),_c('div',{staticClass:"form-page-table-name is-flex is-align-items-center"},[_c('div',{staticClass:"form-page-table-name-text is-flex-shrink-0"},[_vm._v("名")]),_c('validation-provider',{attrs:{"rules":_vm.ruleAccountNameMei,"vid":"name2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name2),expression:"name2"}],staticClass:"input form-page-table-name-input",class:{ 'is-danger' : errors.length > 0},attrs:{"type":"text","name":"名","data-test":"input-name2"},domProps:{"value":(_vm.name2)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name2=$event.target.value}}})]}}],null,true)})],1)]),(invalid)?_c('div',{staticClass:"has-text-danger has-text-weight-bold mt-1",attrs:{"data-test":"input-error-name"}},[_vm._l((errors.name1),function(item){return _c('div',[_vm._v(_vm._s(item))])}),_vm._l((errors.name2),function(item){return _c('div',[_vm._v(_vm._s(item))])})],2):_vm._e()]}}],null,true)})],1)]),_c('tr',{staticClass:"form-page-table-tr",attrs:{"data-test":"form-row-birthday"}},[_c('th',{staticClass:"form-page-table-th"},[_c('div',{staticClass:"is-flex is-align-items-center is-flex-wrap-wrap"},[_c('span',{staticClass:"form-page-table-th-text is-block has-text-weight-bold",attrs:{"data-test":"form-row-heading"}},[_vm._v("生年")]),_c('span',{staticClass:"form-label-required tag is-required",attrs:{"data-test":"form-row-require"}},[_vm._v("必須")])])]),_c('td',{staticClass:"form-page-table-td"},[_c('div',{staticClass:"select form-page-select"},[_c('validation-provider',{attrs:{"rules":_vm.ruleAccountBirthYear},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.birthYear),expression:"birthYear"}],staticClass:"form-page-select-year",class:{ 'is-danger' : errors.length > 0},attrs:{"name":"生年","data-test":"select-birthday"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.birthYear=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":""}},[_vm._v("西暦")]),_vm._l((_vm.birthdayOptions),function(data){return _c('option',{domProps:{"value":data}},[_vm._v(_vm._s(data))])})],2),_c('span',{staticClass:"has-text-danger has-text-weight-bold mt-1",attrs:{"data-test":"input-error-select-year"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]),_c('tr',{staticClass:"form-page-table-tr",attrs:{"data-test":"form-row-sex"}},[_c('th',{staticClass:"form-page-table-th"},[_c('div',{staticClass:"is-flex is-align-items-center is-flex-wrap-wrap"},[_c('span',{staticClass:"form-page-table-th-text is-block has-text-weight-bold",attrs:{"data-test":"form-row-heading"}},[_vm._v("性別")]),_c('span',{staticClass:"form-label-required tag is-required",attrs:{"data-test":"form-row-require"}},[_vm._v("必須")])])]),_c('td',{staticClass:"form-page-table-td form-page-table-td-gender",attrs:{"data-test":"input-sex"}},[_c('div',{staticClass:"is-flex"},[_c('div',{staticClass:"radio-btn mr-5 pr-2"},[_c('validation-provider',{attrs:{"rules":_vm.ruleAccountSex},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sex),expression:"sex"}],class:{ 'is-danger' : errors.length > 0},attrs:{"type":"radio","name":"性別","value":"0","id":"man","checked":""},domProps:{"checked":_vm._q(_vm.sex,"0")},on:{"change":function($event){_vm.sex="0"}}}),_c('label',{staticClass:"radio",attrs:{"for":"man"}},[_vm._v("男性")]),_c('span',{staticClass:"has-text-danger has-text-weight-bold mt-1"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"radio-btn"},[_c('validation-provider',{attrs:{"rules":_vm.ruleAccountSex},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sex),expression:"sex"}],class:{ 'is-danger' : errors.length > 0},attrs:{"type":"radio","name":"性別","value":"1","id":"woman"},domProps:{"checked":_vm._q(_vm.sex,"1")},on:{"change":function($event){_vm.sex="1"}}}),_c('label',{staticClass:"radio",attrs:{"for":"woman"}},[_vm._v("女性")]),_c('span',{staticClass:"has-text-danger has-text-weight-bold mt-1"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])])]),_c('tr',{staticClass:"form-page-table-tr",attrs:{"data-test":"form-row-company"}},[_c('th',{staticClass:"form-page-table-th"},[_c('div',{staticClass:"is-flex is-align-items-center is-flex-wrap-wrap"},[_c('span',{staticClass:"form-page-table-th-text is-block has-text-weight-bold",attrs:{"data-test":"form-row-heading"}},[_vm._v("企業名")]),_c('span',{staticClass:"form-label-required tag is-required",attrs:{"data-test":"form-row-require"}},[_vm._v("必須")])])]),_c('td',{staticClass:"form-page-table-td"},[_c('div',{staticClass:"form-page-table-td-box"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var errors = ref.errors;
return [_c('validation-provider',{attrs:{"rules":_vm.ruleAccountCompanyName,"vid":"company","name":"企業名"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"select form-page-select is-block"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyName),expression:"companyName"}],staticClass:"form-page-select-company",class:{ 'is-danger' : errors.length > 0},attrs:{"name":"企業名","data-test":"input-company"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.companyName=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":""}},[_vm._v("選択してください")]),_vm._l((_vm.companyOptions),function(data){return _c('option',{domProps:{"value":data}},[_vm._v(_vm._s(data))])})],2)])]}}],null,true)}),(invalid)?_c('div',{staticClass:"has-text-danger has-text-weight-bold mt-1"},_vm._l((errors.company),function(item){return _c('div',[_vm._v(_vm._s(item))])}),0):_vm._e()]}}],null,true)})],1)])]),_c('tr',{staticClass:"form-page-table-tr",attrs:{"data-test":"form-row-department"}},[_c('th',{staticClass:"form-page-table-th"},[_c('div',{staticClass:"is-flex is-align-items-center is-flex-wrap-wrap"},[_c('span',{staticClass:"form-page-table-th-text is-block has-text-weight-bold",attrs:{"data-test":"form-row-heading"}},[_vm._v("部署名")]),_c('span',{staticClass:"form-label-required tag is-required",attrs:{"data-test":"form-row-require"}},[_vm._v("必須")])])]),_c('td',{staticClass:"form-page-table-td"},[_c('div',{staticClass:"form-page-table-td-box"},[_c('validation-provider',{attrs:{"rules":_vm.ruleAccountDepartmentName},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.departmentName),expression:"departmentName"}],staticClass:"input",class:{ 'is-danger' : errors.length > 0},attrs:{"type":"text","placeholder":"部署名をご入力ください","name":"部署名","data-test":"input-department"},domProps:{"value":(_vm.departmentName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.departmentName=$event.target.value}}}),_c('span',{staticClass:"has-text-danger has-text-weight-bold mt-1",attrs:{"data-test":"input-error-department"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]),_c('tr',{staticClass:"form-page-table-tr",attrs:{"data-test":"form-row-email"}},[_c('th',{staticClass:"form-page-table-th"},[_c('div',{staticClass:"is-flex is-align-items-center is-flex-wrap-wrap"},[_c('span',{staticClass:"form-page-table-th-text is-block has-text-weight-bold",attrs:{"data-test":"form-row-heading"}},[_vm._v("メールアドレス")]),_c('span',{staticClass:"form-label-required tag is-required",attrs:{"data-test":"form-row-require"}},[_vm._v("必須")])])]),_c('td',{staticClass:"form-page-table-td"},[_c('div',{staticClass:"form-page-table-td-box"},[_c('validation-provider',{attrs:{"rules":_vm.ruleAccountMail,"vid":"mail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.mail),expression:"mail"}],staticClass:"input",class:{ 'is-danger' : errors.length > 0},attrs:{"type":"email","placeholder":"メールアドレスをご記入ください","name":"メールアドレス","data-test":"input-email"},domProps:{"value":(_vm.mail)},on:{"input":function($event){if($event.target.composing){ return; }_vm.mail=$event.target.value}}}),_c('span',{staticClass:"has-text-danger has-text-weight-bold mt-1",attrs:{"data-test":"input-error-email"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('p',{staticClass:"form-page-table-attention is-size-8 mt-2",attrs:{"data-test":"attention"}},[_vm._v("半角英数字でご記入ください。")])])]),_c('tr',{staticClass:"form-page-table-tr",attrs:{"data-test":"form-row-email2"}},[_c('th',{staticClass:"form-page-table-th"},[_c('div',{staticClass:"is-flex is-align-items-center is-flex-wrap-wrap"},[_c('span',{staticClass:"form-page-table-th-text is-block has-text-weight-bold",attrs:{"data-test":"form-row-heading"}},[_vm._v("メールアドレス（確認）")]),_c('span',{staticClass:"form-label-required tag is-required",attrs:{"data-test":"form-row-require"}},[_vm._v("必須")])])]),_c('td',{staticClass:"form-page-table-td"},[_c('div',{staticClass:"form-page-table-td-box"},[_c('validation-provider',{attrs:{"rules":_vm.ruleAccountMailReInput},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.mailReInput),expression:"mailReInput"}],staticClass:"input",class:{ 'is-danger' : errors.length > 0},attrs:{"type":"email","placeholder":"メールアドレスをご記入ください","name":"メールアドレス（確認）","data-test":"input-email2"},domProps:{"value":(_vm.mailReInput)},on:{"input":function($event){if($event.target.composing){ return; }_vm.mailReInput=$event.target.value}}}),_c('span',{staticClass:"has-text-danger has-text-weight-bold mt-1",attrs:{"data-test":"input-error-email2"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('p',{staticClass:"form-page-table-attention is-size-8 mt-2",attrs:{"data-test":"attention"}},[_vm._v("半角英数字でご記入ください。")])])]),_c('tr',{staticClass:"form-page-table-tr",attrs:{"data-test":"form-row-password"}},[_c('th',{staticClass:"form-page-table-th"},[_c('div',{staticClass:"is-flex is-align-items-center is-flex-wrap-wrap"},[_c('span',{staticClass:"form-page-table-th-text is-block has-text-weight-bold",attrs:{"data-test":"form-row-heading"}},[_vm._v("パスワード")]),_c('span',{staticClass:"form-label-required tag is-required",attrs:{"data-test":"form-row-require"}},[_vm._v("必須")])])]),_c('td',{staticClass:"form-page-table-td"},[_c('div',{staticClass:"form-page-table-td-box"},[_c('validation-provider',{attrs:{"rules":_vm.ruleAccountPassword,"vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"input",class:{ 'is-danger' : errors.length > 0},attrs:{"type":"password","name":"パスワード","placeholder":"パスワードをご記入ください","data-test":"input-password","minlength":"4"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('span',{staticClass:"has-text-danger has-text-weight-bold mt-1",attrs:{"data-test":"input-error-password"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('p',{staticClass:"form-page-table-attention is-size-8 mt-2",attrs:{"data-test":"attention"}},[_vm._v("半角英数字4～20文字でご記入ください")])])]),_c('tr',{staticClass:"form-page-table-tr",attrs:{"data-test":"form-row-password2"}},[_c('th',{staticClass:"form-page-table-th"},[_c('div',{staticClass:"is-flex is-align-items-center is-flex-wrap-wrap"},[_c('span',{staticClass:"form-page-table-th-text is-block has-text-weight-bold",attrs:{"data-test":"form-row-heading"}},[_vm._v("パスワード（確認）")]),_c('span',{staticClass:"form-label-required tag is-required",attrs:{"data-test":"form-row-require"}},[_vm._v("必須")])])]),_c('td',{staticClass:"form-page-table-td"},[_c('div',{staticClass:"form-page-table-td-box"},[_c('validation-provider',{attrs:{"rules":_vm.ruleAccountPasswordReInput},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordReInput),expression:"passwordReInput"}],staticClass:"input",class:{ 'is-danger' : errors.length > 0},attrs:{"type":"password","name":"パスワード（確認）","placeholder":"パスワードをご記入ください","data-test":"input-password2"},domProps:{"value":(_vm.passwordReInput)},on:{"input":function($event){if($event.target.composing){ return; }_vm.passwordReInput=$event.target.value}}}),_c('span',{staticClass:"has-text-danger has-text-weight-bold mt-1",attrs:{"data-test":"input-error-password2"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('p',{staticClass:"form-page-table-attention is-size-8 mt-2",attrs:{"data-test":"attention"}},[_vm._v("確認のためにもう一度お願いいたします。")])])]),_c('tr',{staticClass:"form-page-table-tr",attrs:{"data-test":"form-row-secret"}},[_c('th',{staticClass:"form-page-table-th"},[_c('div',{staticClass:"is-flex is-align-items-center is-flex-wrap-wrap"},[_c('span',{staticClass:"form-page-table-th-text is-block has-text-weight-bold",attrs:{"data-test":"form-row-heading"}},[_vm._v("秘密の質問の答え")]),_c('span',{staticClass:"form-label-required tag is-required",attrs:{"data-test":"form-row-require"}},[_vm._v("必須")])])]),_c('td',{staticClass:"form-page-table-td"},[_c('p',{staticClass:"has-text-weight-bold mb-3"},[_vm._v("卒業した小学校は？")]),_c('div',{staticClass:"form-page-table-td-box"},[_c('validation-provider',{attrs:{"rules":_vm.ruleAccountSecretQuestAans},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.secretQuestAans),expression:"secretQuestAans"}],staticClass:"input",class:{ 'is-danger' : errors.length > 0},attrs:{"type":"text","name":"秘密の質問の答え","data-test":"input-secret","maxlength":"10"},domProps:{"value":(_vm.secretQuestAans)},on:{"input":function($event){if($event.target.composing){ return; }_vm.secretQuestAans=$event.target.value}}}),_c('span',{staticClass:"has-text-danger has-text-weight-bold mt-1",attrs:{"data-test":"input-error-secret"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('p',{staticClass:"form-page-table-attention is-size-8 mt-2",attrs:{"data-test":"attention"}},[_vm._v("10文字以内でご記入ください。"),_c('span',{staticClass:"is-hidden-mobile"},[_vm._v("本人確認、パスワード設定時の確認のために使用されます。")])]),_c('p',{staticClass:"form-page-table-attention is-size-8 is-hidden-tablet",attrs:{"data-test":"attention2"}},[_vm._v("本人確認、パスワード設定時の確認のために使用されます。")])])]),_c('tr',{staticClass:"form-page-table-tr",attrs:{"data-test":"form-row-tel"}},[_c('th',{staticClass:"form-page-table-th"},[_c('div',{staticClass:"is-flex is-align-items-center is-flex-wrap-wrap"},[_c('span',{staticClass:"form-page-table-th-text is-block has-text-weight-bold",attrs:{"data-test":"form-row-heading"}},[_vm._v("緊急ご連絡先（電話番号）")]),_c('span',{staticClass:"form-label-required tag is-required",attrs:{"data-test":"form-row-require"}},[_vm._v("必須")])])]),_c('td',{staticClass:"form-page-table-td"},[_c('ValidationObserver',{ref:"observerTel",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var errors = ref.errors;
return [_c('validation-provider',{attrs:{"rules":_vm.ruleAccountTel,"vid":"tel","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tel),expression:"tel"}],staticClass:"input form-page-table-tel-input",class:{ 'is-danger' : errors.length > 0},attrs:{"type":"hidden","name":"緊急ご連絡先（電話番号）","data-test":"input-tel1"},domProps:{"value":(_vm.tel)},on:{"input":function($event){if($event.target.composing){ return; }_vm.tel=$event.target.value}}})]}}],null,true)}),_c('div',{staticClass:"is-flex is-align-items-center",class:{ 'is-danger' : invalid && errors.tel.length > 0}},[_c('div',{staticClass:"form-page-table-td-box is-flex is-align-items-center"},[_c('validation-provider',{attrs:{"rules":_vm.ruleAccountTel1,"vid":"tel1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tel1),expression:"tel1"}],staticClass:"input form-page-table-tel-input",class:{ 'is-danger' : errors.length > 0},attrs:{"type":"tel","name":"緊急ご連絡先（電話番号）1","data-test":"input-tel1","maxlength":"5"},domProps:{"value":(_vm.tel1)},on:{"blur":_vm.onBlurTelFields,"input":function($event){if($event.target.composing){ return; }_vm.tel1=$event.target.value}}})]}}],null,true)}),_c('div',{staticClass:"form-page-table-tel-text is-flex-shrink-0"},[_vm._v("ー")]),_c('validation-provider',{attrs:{"rules":_vm.ruleAccountTel2,"vid":"tel2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tel2),expression:"tel2"}],staticClass:"input form-page-table-tel-input",class:{ 'is-danger' : errors.length > 0},attrs:{"type":"tel","name":"緊急ご連絡先（電話番号）2","data-test":"input-tel2","maxlength":"5"},domProps:{"value":(_vm.tel2)},on:{"blur":_vm.onBlurTelFields,"input":function($event){if($event.target.composing){ return; }_vm.tel2=$event.target.value}}})]}}],null,true)}),_c('div',{staticClass:"form-page-table-tel-text is-flex-shrink-0"},[_vm._v("ー")]),_c('validation-provider',{attrs:{"rules":_vm.ruleAccountTel3,"vid":"tel3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tel3),expression:"tel3"}],staticClass:"input form-page-table-tel-input",class:{ 'is-danger' : errors.length > 0},attrs:{"type":"tel","name":"緊急ご連絡先（電話番号）3","data-test":"input-tel3","maxlength":"5"},domProps:{"value":(_vm.tel3)},on:{"blur":_vm.onBlurTelFields,"input":function($event){if($event.target.composing){ return; }_vm.tel3=$event.target.value}}})]}}],null,true)})],1)]),(invalid)?_c('div',{staticClass:"has-text-danger has-text-weight-bold mt-1"},_vm._l((errors.tel),function(item){return _c('div',{attrs:{"data-test":"input-error-tel"}},[_vm._v(_vm._s(item))])}),0):_vm._e()]}}],null,true)}),_c('p',{staticClass:"form-page-table-attention is-size-8 mt-2",attrs:{"data-test":"attention"}},[_vm._v("講座実施場所変更等の急な連絡が必要な場合に"),_c('br',{staticClass:"is-hidden-tablet"}),_vm._v("ご連絡させていただく場合がございます。")])],1)]),_c('tr',{staticClass:"form-page-table-tr",attrs:{"data-test":"form-row-photoagree"}},[_c('th',{staticClass:"form-page-table-th"},[_c('div',{staticClass:"is-flex is-align-items-center is-flex-wrap-wrap"},[_c('span',{staticClass:"form-page-table-th-text is-block has-text-weight-bold",attrs:{"data-test":"form-row-heading"}},[_vm._v("広報用写真への掲載を"),_c('br',{staticClass:"is-hidden-mobile"}),_vm._v("承諾いただけますか？")]),_c('span',{staticClass:"form-label-required tag is-required",attrs:{"data-test":"form-row-require"}},[_vm._v("必須")])])]),_c('td',{staticClass:"form-page-table-td",attrs:{"data-test":"input-photoagree"}},[_c('div',{staticClass:"is-flex"},[_c('div',{staticClass:"radio-btn mr-5"},[_c('validation-provider',{attrs:{"rules":_vm.ruleAccountConsent},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.consent),expression:"consent"}],class:{ 'is-danger' : errors.length > 0},attrs:{"type":"radio","name":"広報用写真への掲載を承諾いただけますか？","value":"0","id":"yes","checked":""},domProps:{"checked":_vm._q(_vm.consent,"0")},on:{"change":function($event){_vm.consent="0"}}}),_c('label',{staticClass:"radio",attrs:{"for":"yes"}},[_vm._v("はい")]),_c('span',{staticClass:"has-text-danger has-text-weight-bold mt-1"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"radio-btn"},[_c('validation-provider',{attrs:{"rules":_vm.ruleAccountConsent},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.consent),expression:"consent"}],class:{ 'is-danger' : errors.length > 0},attrs:{"type":"radio","name":"広報用写真への掲載を承諾いただけますか？","value":"1","id":"no"},domProps:{"checked":_vm._q(_vm.consent,"1")},on:{"change":function($event){_vm.consent="1"}}}),_c('label',{staticClass:"radio",attrs:{"for":"no"}},[_vm._v("いいえ")]),_c('span',{staticClass:"has-text-danger has-text-weight-bold mt-1"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('p',{staticClass:"form-page-table-attention is-size-8 mt-3",attrs:{"data-test":"attention"}},[_vm._v("基本的にお客様個人が特定される写真ではございません。")]),_c('p',{staticClass:"form-page-table-attention is-size-8",attrs:{"data-test":"attention2"}},[_vm._v("Webサイトや会報誌などで、ご参加頂く講座の全体写真などを掲載させていただく場合がございます。")])])])]),_c('div',{staticClass:"is-flex is-justify-content-center mt-6"},[_c('button',{staticClass:"form-page-send-btn is-fullwidth button is-medium is-rounded is-primary",attrs:{"type":"submit","data-test":"next-btn"}},[_vm._v("規約に同意して確認画面へ進む")])])],1)]}}])}),_c('div',{staticClass:"has-text-centered mt-5 pt-4"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.pageBack.apply(null, arguments)}}},[_vm._v("前の画面へ戻る")])])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"form-page-lead has-background-white-ter has-text-centered-tablet",attrs:{"data-test":"lead"}},[_vm._v(" ワーカーズフォーラムMOCは、ゲートシティ大崎入居企業にお勤めの方に向けた会員サービスです。"),_c('br',{staticClass:"is-hidden-touch"}),_vm._v(" 登録内容に関しまして、MOC事務局より確認させていただく場合がございますので、あらかじめご了承ください。 ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"is-flex-tablet is-align-items-center pt-4 mb-4 pb-1"},[_c('h2',{staticClass:"form-page-ttl02 title is-3 has-text-secondary mb-0 has-text-weight-bold"},[_vm._v("ワーカーズフォーラムMOC会員規約")]),_c('p',{staticClass:"is-size-8-mobile"},[_vm._v("※必ずお読みください")])])}]

export { render, staticRenderFns }