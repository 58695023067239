import { render, staticRenderFns } from "./Form.html?vue&type=template&id=53fbfdaa&scoped=true&"
import script from "./Form.vue?vue&type=script&lang=ts&"
export * from "./Form.vue?vue&type=script&lang=ts&"
import style0 from "./Form.scss?vue&type=style&index=0&id=53fbfdaa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53fbfdaa",
  null
  
)

export default component.exports